<template>
	<div id="chatmsg" ref="msg">
		<!-- 动态创建 -->

		<div v-for="(item,index) in historyList.data" :key="index">
			<div v-if="item.from_type==0" class="user">
				<div>
					<img :src="imageUrl + item.avatar" alt />
					<div class="bubble">
						<span>{{item.content}}</span>
					</div>
				</div>
			</div>
			<div v-if="item.from_type==1" class="touser">
				<div>
					<img :src="imageUrl +item.image" alt />
					<div class="tobubble">
						<span>{{item.content}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	export default {
		name: "ChatMsg",
		data() {
			return {
				userimg: "",
				lists: [],
				imageUrl: this.url.imageUrl
			};
		},
		props: ['historyList','scrollTop'],


		mounted() {
		
			
		},
		
		
		methods: {
			
			
			formatDate(datetime) {
				var date = new Date(datetime); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var year = date.getFullYear(),
					month = ("0" + (date.getMonth() + 1)).slice(-2),
					sdate = ("0" + date.getDate()).slice(-2),
					hour = ("0" + date.getHours()).slice(-2),
					minute = ("0" + date.getMinutes()).slice(-2),
					second = ("0" + date.getSeconds()).slice(-2);
				// 拼接
				var result = year + "-" + month + "-" + sdate + " " + hour + ":" + minute + ":" + second;
				// 返回
				return result;
			},
			saveMsg(tomsg) {
				console.log(tomsg)
				console.log(this.historyList.data)
				let msg = {}
				let newMsg
				var lastMsg
				if(tomsg.from_type){
					newMsg = tomsg
				}else{
					this.historyList.data.forEach(item => {
						if (item.from_type == 0) {
							lastMsg = item
						}
					})
					newMsg = Object.assign(msg, lastMsg)
					newMsg.create_time = this.formatDate(new Date().getTime())
					newMsg.content = tomsg
				}
				

				
				
				this.historyList.data.push(newMsg);
			

			},
			
		}
	};
</script>

<style lang="scss" scoped="scoped">
	#chatmsg {
		// position: relative;
		// top: 3.5rem;
		width: 100%;
		height: 100%;
		// min-height: 44rem;
		background-color: rgb(238, 212, 238);
		// margin-bottom: 3.5rem;
		overflow-x: hidden;
		overflow-y: scroll;

		.user {
			position: relative;
			width: 100%;
			overflow: hidden;
			margin: 0.8rem 0;

			img {
				object-fit: cover;
				width: 3rem;
				height: 3rem;
				border-radius: 3px;
				float: right;
				margin-right: 1rem;
			}

			.bubble {
				position: relative;
				float: right;
				margin-right: 1rem;
				padding: 0.8rem;
				box-sizing: border-box;
				border-radius: 3px;
				max-width: 65%;
				background-color: rgb(116, 228, 116);

				span {
					height: 1.25rem;
					line-height: 1.25rem;
				}
			}

			.bubble::after {
				position: absolute;
				right: -1.3rem;
				top: 0.8rem;
				content: "";
				width: 0;
				height: 0;
				border: 0.7rem solid;
				border-color: transparent transparent transparent rgb(116, 228, 116);
			}
		}

		.touser {
			position: relative;
			width: 100%;
			overflow: hidden;
			margin: 0.8rem 0;

			img {
				object-fit: cover;
				width: 3rem;
				height: 3rem;
				border-radius: 3px;
				float: left;
				margin-left: 1rem;
			}

			.tobubble {
				position: relative;
				float: left;
				margin-left: 1rem;
				padding: 0 0.7rem;
				box-sizing: border-box;
				border-radius: 3px;
				max-width: 65%;
				background-color: rgb(116, 228, 116);
				line-height: 3rem;
			}

			.tobubble::after {
				position: absolute;
				left: -1.3rem;
				top: 0.8rem;
				content: "";
				width: 0;
				height: 0;
				border: 0.7rem solid;
				border-color: transparent rgb(116, 228, 116) transparent transparent;
			}
		}
	}
</style>
