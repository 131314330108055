<template>
	<div id="chatdetail">
		<!-- <div class="chattop">
			<div @click="goback" class="chattop_back">
				<van-icon name="arrow-left" />

			</div>
			<div class="chattop_name">{{storeName}}</div>
			<div class="chattop_more">
				<van-icon name="ellipsis" />
			</div>
		</div> -->
		<div class="chatcontent" ref="chatContent">
			<!-- <van-pull-refresh v-model="state.loading" @refresh="onRefresh"> -->
			<!-- <ChatMsg ref="chatmsg" :historyList="historyList" :scrollTop="scrollTop"/> -->
			<div id="chatmsg" ref="msg">
				<!-- 动态创建 -->

				<div v-for="(item,index) in historyList" :key="index" v-if="historyList.length>0">
					<div v-if="item.from_type==0" class="user">
						<span class="time">{{item.create_time}}</span>
						<div>
							<img :src="imageUrl + item.avatar" alt />
							<div class="bubble">
								<span>{{item.content}}</span>
							</div>
						</div>
					</div>
					<div v-if="item.from_type==1" class="touser">
						<span class="time">{{item.create_time}}</span>
						<div>
							<img :src="imageUrl +item.image" alt />
							<div class="tobubble">
								<span>{{item.content}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- </van-pull-refresh> -->
		</div>
		<div class="chatfooter">

			<div>
				<input ref="sendcontent" @keypress="sendmsg" :type="istype" :value="isvalue" @focus="focus" />
			</div>
			<div class="sendBtn" @click="btnSend">
				<span>发送</span>
			</div>
			<!-- <div>
				<van-icon name="smile-o" size="20" />

			</div>
			<div>
				<van-icon name="add-o" size="20" />
			</div> -->
		</div>
	</div>
</template>


<script>
	import vueCookie from 'vue-cookies'
	const TokenKey = 'Token'
	import {
		ref
	} from 'vue';
import {
		info
		
	} from '@/api/user'
	import {
		createChat,
		getChatHistory
	} from '../../api/user.js'
	import ChatMsg from "./ChatMsg"

	export default {
		name: 'test',

		data() {
			return {
				imageUrl: this.url.imageUrl,
				websock: null,
				contentText: '',
				name: null,
				issound: "xiaoxitongzhi",
				istype: "text",
				isvalue: "",
				isshow: false,
				tomsg: "",
				msgchild: null,
				websocketObj: null,
				wsHeartflag: false,
				reconnectTime: 0,
				gid: '',
				heartCheck: '',
				lockReconnect: false, //是否真正建立连接
				timeout: 5 * 1000, //30秒一次心跳
				timeoutObj: null, //心跳心跳倒计时
				serverTimeoutObj: null, //心跳倒计时
				timeoutnum: null, //断开 重连倒计时
				storeName: this.$route.query.storeName,
				historyList: '',

				isUpLoading: true, // 上拉加载

				page: 1,
				limit: 20,
				scrollTop: 0,
				scrollHeight: 0,
				scrollHeightNew:0,
userInfo:''
			}
		},
		// components: {
		// 	ChatMsg: ChatMsg
		// },
		beforeRouteEnter(to, from, next) {
			console.log(from)
			next((vm) => {
				// to.meta.title = vm.$route.query.storeName
				console.log(vm.$route.query)
				if (from.path == '/information' || from.path == '/') {
					vm.gid = vm.$route.query.gid
					vm.getChatHistory(vm.gid).then((res) => {
											vm.historyList = res.data.data
											vm.initWebSocket();
										})
				} else {
					vm.getChatHistory().then((res) => {
						vm.historyList = res.data.data
						vm.initWebSocket();
					})
					// createChat({
					// 	sid: vm.$route.query.sid
					// }).then((res) => {
					// 	vm.gid = res.data.gid
					// })
				}
				console.log(from.meta)
			})

		},
		created() {

		},
		updated: function() {
			this.scrollToBottom();
			// this.listenerFunction()
		},

		mounted() {
			console.log(this.$route.query.storeName)
info().then((res)=>{
	console.log(res)
	this.userInfo = res.data.info
})
			this.scrollToBottom()
			this.listenerFunction()
			this.initWebSocket();
			// this.getChatHistory(this.gid).then((res) => {
			// 	this.historyList = res.data.data
			// 	this.initWebSocket();
			// })

			this.msgchild = this.$refs.chatmsg;
		},
		unmounted() {
			this.websock.close() //离开路由之后断开websocket连接
			clearTimeout(this.timeoutObj)
		},
		methods: {
			getGid(){
				return new Promise((resolve,reject)=>{
					createChat({
						sid: this.$route.query.sid
					}).then((res) => {
						 resolve(res.data.gid)
						// this.gid = res.data.gid
					})
				})
			},
			//按钮发送
			btnSend() {
				
				let sendcontent = this.$refs.sendcontent.value;
				this.tomsg = sendcontent;
				console.log(this.tomsg)
				if(this.tomsg){
					this.saveMsg(this.$refs.sendcontent.value)
					var data = {
						"type": "message",
						"platform": "u",
						"data": {
							"gid": this.gid,
							"content": this.tomsg,
							"content_type": 0
						}
					}
					
					
					this.websocketsend(JSON.stringify(data));
				}
				
				
			},
			//输入框聚焦
			focus() {
				// this.listenerFunction()
				var scrollTop = this.$el.querySelector('#chatmsg')
				scrollTop.scrollTop = this.scrollHeight
				
			},
			goback() {
				this.$router.go(-1)
			},
			//时间格式化
			formatDate(datetime) {
				var date = new Date(datetime); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
				var year = date.getFullYear(),
					month = ("0" + (date.getMonth() + 1)).slice(-2),
					sdate = ("0" + date.getDate()).slice(-2),
					hour = ("0" + date.getHours()).slice(-2),
					minute = ("0" + date.getMinutes()).slice(-2),
					second = ("0" + date.getSeconds()).slice(-2);
				// 拼接
				var result = year + "-" + month + "-" + sdate + " " + hour + ":" + minute + ":" + second;
				console.log(result)
				// 返回
				return result;
			},

			//发送消息
			saveMsg(tomsg) {
              var that = this
				let msg = {}
				let newMsg
				var lastMsg
				if (tomsg.from_type) {
					tomsg.create_time =  this.formatDate(new Date().getTime())
					newMsg = tomsg
					this.historyList.push(newMsg);
				} else {
					if(this.historyList.length > 0){
						this.historyList.forEach(item => {
							if (item.from_type == 0) {
								lastMsg = item
							}
						})
						newMsg = Object.assign(msg, lastMsg)
						newMsg.create_time = this.formatDate(new Date().getTime())
						newMsg.content = tomsg
						this.historyList.push(newMsg);
						console.log(newMsg.create_time)
					}else{
						let newMsgs ={}
						console.log(that.gid)
						newMsgs.gid = that.gid
						newMsgs.from_type = 0
						newMsgs.content_type = 0
						newMsgs.content = tomsg
						newMsgs.create_time =  this.formatDate(new Date().getTime())
						newMsgs.sid = that.$route.query.sid
						newMsgs.uid = that.userInfo.uid
						newMsgs.avatar = that.userInfo.avatar
						newMsgs.image =''
						console.log(newMsgs)
						this.historyList.push(newMsgs);
					}
					
				}
				
				console.log(this.historyList)
			},
			//滚动条监听
			listenerFunction(e) {
				document.addEventListener("scroll", this.handleScroll, true);
			},
			handleScroll(e) {
				
				var that = this
				if (e.target.scrollTop == 0 && this.isUpLoading) {
					that.page++
					that.getChatHistory(that.gid).then((res) => {
						res.data.data.forEach((v, i) => {
							this.historyList.unshift(v)
						})
						if (res.data.data.length < this.limit) {
							this.isUpLoading = false

						}

					})
					this.scrollHeightNew = e.target.scrollHeight
				}else{
					this.scrollHeightNew = e.target.scrollTop + e.target.scrollHeight
				}												
			},
			//滚动条位置
			scrollToBottom() {			
				var scrollTop = this.$el.querySelector('#chatmsg')
				this.scrollHeight = scrollTop.scrollHeight		
				this.$nextTick(() => {					
					if (this.scrollHeight - this.scrollHeightNew > 0) {
						scrollTop.scrollTop = this.scrollHeight - this.scrollHeightNew
					} else {
						scrollTop.scrollTop = this.scrollHeightNew					
					}
				})
			},
			//获取数据列表
		async	getChatHistory(val) {
			
			let params = {}
			params.page = this.page
			params.limit = this.limit
			if(val){
				params.gid = val
			}else{
				params.gid = await this.getGid()
			}
				
				this.gid = params.gid
				
				return getChatHistory(params)
			},

			sendmsg(e) {
				// 1、用ref定义输入回复内容的input文本框，定义sendcontent变量接收其value值（输入的内容）
				
				let sendcontent = this.$refs.sendcontent.value;
				if (e.keyCode === 13 && sendcontent.split(" ").join("").length !== 0) {
					// 2、将ChatDetail（父）组件中的sendcontent（文本框输入的值）先用tomsg接收
					this.tomsg = sendcontent;
					// 3、用ref定义ChatMsg（子）组件，并在mounted中使用$refs获取，即this.msgchild
					// 4、调子组件里的方法，并将tomsg传到ChatMsg（子）组件（具体的聊天内容）中
					this.saveMsg(this.tomsg);
					// 5、发送完一条信息之后，需清空文本框
					this.$refs.sendcontent.value = "";
					// 回车时，调用子组件的随机消息的方法
					// this.msgchild.randomMsg();
					var data = {
						"type": "message",
						"platform": "u",
						"data": {
							"gid": this.gid,
							"content": this.tomsg,
							"content_type": 0
						}
					}


					this.websocketsend(JSON.stringify(data));
				}
			},

			initWebSocket() { //初始化weosocket
				const wsuri = "wss://server.esw235.cn/wss";
				this.websock = new WebSocket(wsuri);
				this.websock.onmessage = this.websocketonmessage;
				this.websock.onopen = this.websocketonopen;
				this.websock.onerror = this.websocketonerror;
				this.websock.onclose = this.websocketclose;


			},
			start() {
				// console.log('开启心跳');
				const self = this;
				let _num = 3;
				self.timeoutObj && clearTimeout(self.timeoutObj);
				self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
				self.timeoutObj = setTimeout(function() {
					//这里发送一个心跳，后端收到后，返回一个心跳消息，
					// console.log(self.websock)
					if (self.websock.readyState === 1) { //如果连接正常
						// console.log(self.websock);
						let heartCheck = {
							"type": "ping",
							"platform": "u",
							"data": {

							}
						}
						self.websock.send(JSON.stringify(heartCheck));
					} else { //否则重连
						self.reconnect();
					}
					self.serverTimeoutObj = setTimeout(function() {
						//超时关闭
						_num--;
						//计算答复的超时次数
						if (_num === 0) {
							self.websocketclose();
						}
					}, self.timeout);

				}, self.timeout)
			},
			websocketonopen() { //连接建立之后执行send方法发送数据

				let actions = {
					"type": "login",
					"platform": "u",
					"data": vueCookie.get(TokenKey)
				}

				this.websocketsend(JSON.stringify(actions));
				console.log("WebSocket连接成功");
				this.start();
			},

			websocketonerror() { //连接建立失败重连

				this.initWebSocket();
			},
			websocketonmessage(e) { //数据接收
				var that = this
				let redata
				// console.log(that.msgchild)
				that.reset();
				// console.log(JSON.parse(e.data))
				if (JSON.parse(e.data).type == "message") {
					redata = JSON.parse(e.data);
					that.saveMsg(redata.data);

				}


			},
			websocketsend(Data) { //数据发送

				this.websock.send(Data);
			},
			websocketclose(e) { //关闭

				console.log('断开连接', e);
				//重连
				// this.reconnect();
			},
			reset() {
				// console.log('重置心跳');
				var that = this;
				//清除时间
				clearTimeout(that.timeoutObj);
				clearTimeout(that.serverTimeoutObj);
				//重启心跳
				that.start();
			},
			reconnect() { //重新连接
				var that = this;
				if (that.lockReconnect) {
					return;
				}
				that.lockReconnect = true;
				//没连接上会一直重连，设置延迟避免请求过多
				that.timeoutnum && clearTimeout(that.timeoutnum);
				that.timeoutnum = setTimeout(function() {
					//新连接
					that.initWebSocket();
					that.lockReconnect = false;
				}, 5000);
			},
		},
	}
</script>


<style scoped lang="less">
	#chatdetail {
		position: relative;
		background-color: rgb(238, 212, 238);
		height: 100%;

		.chattop {
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;
			width: 100%;
			height: 3.5rem;
			line-height: 3.5rem;
			background-color: rgb(240, 240, 240) !important;
			display: flex;
			flex-direction: row;

			.chattop_back {
				flex: 1;
				margin-left: 1rem;
			}

			.chattop_name {
				flex: 20;
				text-align: center;
			}

			.chattop_more {
				flex: 1;
				margin-right: 1rem;
			}
		}

		.chatcontent {
			width: 100%;
			height: calc(100% - 56px);
			// padding-bottom: 60px;
			position: absolute;
			top:0;

			// overflow-y: scroll;
			#chatmsg {
				// position: relative;
				// top: 3.5rem;
				width: 100%;
				height: 100%;
				// min-height: 44rem;
				background-color: rgb(238, 212, 238);
				// margin-bottom: 3.5rem;
				overflow-x: hidden;
				overflow-y: scroll;

				.user {
					position: relative;
					width: 100%;
					overflow: hidden;
					margin: 0.8rem 0;
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					img {
						object-fit: cover;
						width: 3rem;
						height: 3rem;
						border-radius: 3px;
						float: right;
						margin-right: 1rem;
					}

					.time {
						float: right;
						font-size: 12px;
						color: #999999;
						margin-bottom: 10px;
						padding-right: 15px;
					}

					;

					.bubble {
						position: relative;
						float: right;
						margin-right: 1rem;
						padding: 0.8rem;
						box-sizing: border-box;
						border-radius: 3px;
						max-width: 65%;
						background-color: rgb(116, 228, 116);
                        word-break: break-all;
                        word-wrap: break-word;

						span {
							height: 1.25rem;
							line-height: 1.25rem;

						}
					}

					.bubble::after {
						position: absolute;
						right: -1.3rem;
						top: 0.8rem;
						content: "";
						width: 0;
						height: 0;
						border: 0.7rem solid;
						border-color: transparent transparent transparent rgb(116, 228, 116);
					}
				}

				.touser {
					position: relative;
					width: 100%;
					overflow: hidden;
					margin: 0.8rem 0;
					display: flex;
					flex-direction: column;

					img {
						object-fit: cover;
						width: 3rem;
						height: 3rem;
						border-radius: 3px;
						float: left;
						margin-left: 1rem;
					}

					.time {
						float: left;
						font-size: 12px;
						color: #999999;
						margin-bottom: 10px;
						padding-left: 15px;
					}

					;

					.tobubble {
						position: relative;
						float: left;
						margin-left: 1rem;
						padding: 0.8rem;
						box-sizing: border-box;
						border-radius: 3px;
						max-width: 65%;
						background-color: rgb(116, 228, 116);
                        word-break: break-all;
                        word-wrap: break-word;
						span {
							height: 1.25rem;
							line-height: 1.25rem;

						}
					}

					.tobubble::after {
						position: absolute;
						left: -1.3rem;
						top: 0.8rem;
						content: "";
						width: 0;
						height: 0;
						border: 0.7rem solid;
						border-color: transparent rgb(116, 228, 116) transparent transparent;
					}
				}
			}
		}

		.chatfooter {
			.sendBtn {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 8px;
				padding: 0 10px;
				height: 100%;
				background-color: #74e474;
				margin-left: 20px;
				color: #FFFBE8;
			}

			position: fixed;
			padding: 10px 15px;
			box-sizing: border-box;
			left: 0;
			bottom: 0;
			z-index: 10;
			width: 100%;
			height: 3.5rem;
			line-height: 3.5rem;
			text-align: center;
			background-color: rgb(240, 240, 240) !important;
			display: flex;
			flex-direction: row;
			align-items: center;

			div:nth-child(2),
			div:nth-child(3) {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;

				svg {
					font-size: 1.5rem;
					margin-top: 0.9rem;
				}
			}

			div:nth-child(1) {
				flex: 6;

				input {
					width: 100%;
					height: 2.5rem;
					outline: none;
					padding-left: 0.5rem;
					box-sizing: border-box;
					height: 2.5rem;

					border-style: none;
					font-size: 0.9rem;
					border-radius: 4px;
					background-color: #fff;
					color: #000;
				}
			}
		}
	}
</style>
